<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import { mobileNameList } from '@/utils/enum'
  export default {
    watch:{
      '$route'(){
        this.initRecalc()
      }
    },
    created() {
      //禁止双指缩放
      window.onload = function() {
        document.addEventListener('touchstart', function(event) {
          if (event.touches.length > 1) {
            event.preventDefault()
          }
        })
        document.addEventListener('gesturestart', function(event) {
          event.preventDefault()
        })
      }
    },
    methods: {
      recalc() {
        let clientWidth = document.documentElement.clientWidth || document.body.clinetWidth;
        if (!clientWidth) return;
        const mobileName = this.$route.name
        if(mobileNameList.includes(mobileName)){
           document.documentElement.style.fontSize = 100 * (clientWidth / 750) + 'px';
        } else {
          document.documentElement.style.fontSize = '16px'
        }
      },
      initRecalc(){
        this.recalc();
        let resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
        if (!document.addEventListener) return;
        window.addEventListener(resizeEvt, this.recalc, false);
        document.addEventListener('DOMContentLoaded', this.recalc, false);
      }
    }
  }
</script>

<style lang="scss">
html,body,#app{
  height: 100%;
}
#app{
  background: $main_bg_color;
}
.el-message__icon{
  font-size: 16px;
}
</style>
