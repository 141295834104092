import { baseURL, baseVerifyUrl } from '@/config/env'
const apiUrl = {
    postLogin: `${baseURL}/admin/common/login`, /**登录 */
    postExitLogin: `${baseURL}/admin/common/logout`, /**退出登录*/
    postRegistered: `${baseURL}/admin/common/reg`, /**注册 */
    postForgetPassword: `${baseURL}/admin/common/forget_password`, /**忘记密码 */
    postRegisteredCode: `${baseURL}/admin/common/send_verify`, /**发送验证码 */
    getToken: `${baseURL}/api/token/get_token`, /**获取token */
    postApplyEnjoy: `${baseURL}/api/company_apply/apply`, /*体验申请*/
    getWithdrawType: `${baseURL}/api/type/withdraw_type_list`, /*提现类型*/
    postApplyWithraw: `${baseURL}/api/withdraw/add`, /*申请提现*/
    getWithdrawList: `${baseURL}/api/withdraw/index`, /*提现列表*/
    getCommissionRecord: `${baseURL}/api/balance_log/index`, /*佣金记录*/
    getMyCommissionRecord: `${baseURL}/api/balance/detail`, /*佣金统计详情*/ 
    getUserInfo: `${baseURL}/api/user/info`, /*获取用户资料*/
    getAddress: `${baseURL}/admin/area/getAreaList`, /*省市区*/
    getRefundList: `${baseURL}/api/son_refund/index`, /*下级退款记录*/
    queryCompany: `${baseURL}/api/open/check_company`, /*检查公司*/
    verifyCompany: `${baseVerifyUrl}/api/admin/marketing/verification_company`, /*检查公司名、电话是否存在*/
    policyDetails: `${baseVerifyUrl}/api/admin/public/getAgreement` /*隐私政策*/
}
export default apiUrl