import {   
    Message
} from 'element-ui';
/**成功 */
export const successPrompt = (msg) => {
    Message.closeAll()
    Message({
        message: msg,
        type: 'success'
    })
}
/**错误 */
export const errorPrompt = (msg) => {
    Message.closeAll()
    Message({
        message: msg,
        type: 'error'
    })
}