import Vue from 'vue'
import Vuex from 'vuex'
import md5 from 'js-md5'
import { postHttp, getHttp } from '@/utils/http'
import apiUrl from '@/utils/api'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    recordInfo: {},
    sign_key: '8836a84be0226d4ea1b40075cedc22cb',
    formCodeValid: '',
    isExite: false || localStorage.getItem('isExite') // 是否显示退出按钮
  },
  mutations: {
    SET_USER_INFO(state, data){
      state.userInfo = data
    },
    SET_MY_RECORD(state, data){
      state.recordInfo = data
    },
    SET_REF_FORM(state, value) {
      state.formCodeValid = value
    },
    SET_EXITE_BUT(state, value) {
      state.isExite = value
    }
  },
  actions: {
    getToken({state, dispatch}, { userId, isDetails, system_type }) {
      const signTime = String(new Date().getTime()).substr(0, 10)
      const systemType = system_type || localStorage.getItem('systemType')
      const userInfo = {
        user_id: userId,
        time: signTime,
        system_type: systemType
      }
      let resSort = Object.keys(userInfo).sort(); 
      let _str = ''
      for(let key in resSort){
        _str += `${resSort[key]}=${userInfo[resSort[key]]}&`
      }
      const sign = `${_str}key=${state.sign_key}`
      return new Promise((resolve, reject) => {
        postHttp(apiUrl.getToken, {
          user_id: userId,
          time: signTime,
          sign: md5(sign).toUpperCase(),
          system_type: systemType
        }).then(res => {
          if(res.code == 1) {
            localStorage.setItem('token', res.data.token)
            !isDetails && dispatch('getMyRecord')
            dispatch('getUserInfo')
          }
          resolve(res.code)
        }).catch((err) => { 
          console.log('err',JSON.stringify(err)) 
          reject(err) 
        })
      })
    },
    //获取邀请码
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getHttp(apiUrl.getUserInfo).then(res => {
          commit('SET_USER_INFO', res.data)
          resolve(res.data)
        }).catch((err) => { reject(err) })
      })
     
    },
    // 获取佣金明细
    getMyRecord({ commit }) {
      getHttp(apiUrl.getMyCommissionRecord).then(res => {
        commit('SET_MY_RECORD', res.data)
      })
    }
  },
  modules: {
  }
})
