import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('@/views/reset.vue'),
    meta: {
      title: '重置密码'
    }
  },
  {
    path: '/registered',
    name: 'Registered',
    component: () => import('@/views/registered.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: '活动'
    }
  },
  {
    
    path: '/rules',
    name: 'Rules',
    component: () => import('@/views/rules.vue'),
    meta: {
      title: '活动规则'
    }
  },
  {
    // 合作
    path: '/teamwork',
    name: 'Teamwork',
    component: () => import('@/views/teamwork.vue'),
    meta: {
      title: '商业合作'
    }
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('@/views/detail.vue'),
    meta: {
      title: '佣金明细'
    }
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/policy.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '*',
    component: () => import('@/views/404.vue')
  },
  /**移动端 */
  {
    path: '/mobileHome',
    name: 'mobileHome',
    component: () => import('@/views/mobile/Home.vue'),
    meta: {
      title: '活动'
    }
  },
  {
    path: '/mobileActivityRules',
    name: 'mobileActivityRules',
    component: () => import('@/views/mobile/activityRules.vue'),
    meta: {
      title: '活动规则'
    }
  },
  {
    path: '/mobileApplyExperience',
    name: 'mobileApplyExperience',
    component: () => import('@/views/mobile/applyExperience.vue'),
    meta: {
      title: '提交申请体验'
    }
  },
  {
    path: '/mobileDetail',
    name: 'mobileDetail',
    component: () => import('@/views/mobile/detail.vue'),
    meta: {
      title: '佣金明细'
    }
  },
  {
    path: '/mobileDeposit',
    name: 'mobileDeposit',
    component: () => import('@/views/mobile/deposit.vue'),
    meta: {
      title: '佣金提现申请'
    }
  }
]
const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  const intercept = [ '/rules', '/detail', '/mobileActivityRules', '/mobileDetail', '/mobileDeposit' ]
  if(intercept.includes(to.path) && !localStorage.getItem('token')) {
    next({ path: '/' })
  } else {
    next()
  }
  
})

export default router
