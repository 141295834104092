import _axios from 'axios'
import router from '../router'
import {Toast} from 'vant';
import { errorPrompt } from '@/utils/message'
import { mobileNameList } from '@/utils/enum'
import { getStorage, removeStroage } from '@/utils/common'
// axios.defaults.timeout = 10000 //超时
let axios = _axios.create({
    timeout: 10000,
    headers: {
        'content-type': 'application/json; charset=utf-8'
    }
})
// 请求拦截
axios.interceptors.request.use(
    config => {
        if(!config.headers['XX-Token'] && getStorage('token')) {
            config.headers['XX-Token'] = getStorage('token') || ''
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)
//响应拦截
axios.interceptors.response.use(
    response => {
        if(response.data.code != 1) {
            const routerName = router.currentRoute.name
            if(mobileNameList.includes(routerName)) {
                Toast(response.data.msg || '失败')
            } else {
                errorPrompt(response.data.msg || '失败')
            }
            // return Promise.reject(response.data)
        }
        if(response.data.code == 501){
            //登录过期
            removeStroage('token')
            router.push({ path: '/' })
        }
        return Promise.resolve(response.data)
    },
    error => {
        console.log(error)
        return Promise.reject(error.response)
    }
)
export default axios 