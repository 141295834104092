
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'babel-polyfill'
import promise from 'es6-promise'
promise.polyfill()

Vue.config.productionTip = false
import './assets/style/reset.scss'
import apiUrl from '@/utils/api'
import { successPrompt, errorPrompt } from '@/utils/message'
import { postHttp, getHttp } from '@/utils/http'


// import Vconsole from 'vconsole'
// new Vconsole()

import {   
  Form,
  FormItem,
  Input,
  Button,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  
  MessageBox,
  Select,
  Option,
  Cascader
} from 'element-ui';
import { Form as form, Field, Button as button, Toast, Dialog as dialog, Picker, Popup } from 'vant';
Vue.use(Form).use(FormItem).use(Input).use(Button).use(Table).use(TableColumn).use(Pagination).use(Dialog).use(Select).use(Option).use(Cascader)
Vue.use(form).use(Field).use(button).use(Toast).use(dialog).use(Picker).use(Popup)

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})
Object.assign(Vue.prototype, {
  $api: apiUrl,
  $postHttp: postHttp,
  $getHttp: getHttp,
  $successPrompt: successPrompt,
  $errorPrompt: errorPrompt,
  $confirm: MessageBox.confirm
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
