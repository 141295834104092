import md5 from 'js-md5'
// 获取host
export const getHotUrl = (route) => {
    let wPath = window.document.location.href;
    let pathName = route.path;
    let pos = wPath.indexOf(pathName);
    let localhostPath = wPath.substring(0, pos);
   return localhostPath
}

// 缓存数据
export const setStorage = (name, value) => {
    localStorage.setItem(name, value)
}
// 获取缓存
export const getStorage = (name) => {
   return localStorage.getItem(name)
}
//删除缓存
export const removeStroage = (name) => {
    localStorage.removeItem(name)
}

//加密
export const mdSignKey = (sign_key, val, valKey) => {
    const userInfo = {
        [valKey]: val
    }
    let resSort = Object.keys(userInfo).sort(); 
    let _str = ''
    for(let key in resSort){
        _str += `${resSort[key]}=${userInfo[resSort[key]]}&`
    }
    const sign = `${_str}key=${sign_key}`
    return md5(sign).toUpperCase()
}