import axios from './axios'
const getHttp = (url, data={}) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: data
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
const postHttp = (url, data={}) => {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
export {
    getHttp,
    postHttp
}